<template>
  <div class="my-activities-wrap">
    <div class="date-wrap">
      <div class="radio-date">
        <input
          id="monthCheck5"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="1"
        >
        <label for="monthCheck5">1개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck6"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="3"
        >
        <label for="monthCheck6">3개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck7"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="6"
        >
        <label for="monthCheck7">6개월</label>
      </div>
      <div class="radio-date">
        <input
          id="monthCheck8"
          v-model="monthCheck"
          type="radio"
          name="radioDate"
          value="12"
        >
        <label for="monthCheck8">1년</label>
      </div>

      <div
        class="period-calendar-wrap"
        style="display: flex; position: relative"
      >
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="calendarFr"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>
        <label class="bu-wave" />
        <div class="text-wrap calendar-type">
          <date-picker
            v-model="calendarTo"
            :lang="datePicker.lang"
            value-type="format"
          />
        </div>

        <button
          class="btn-period"
          @click.stop="searchHistory"
        >
          검색
        </button>
      </div>
    </div>

    <div class="tab-nav-in">
      <ul>
        <li :class="{ 'on': tab === 'CommentList' }">
          <a @click.prevent="changeTab('CommentList')">댓글 작성 내역</a>
        </li>
        <li :class="{ 'on': tab === 'SurveyList' }">
          <a @click.prevent="changeTab('SurveyList')">설문 참여 내역</a>
        </li>
        <li :class="{ 'on': tab === 'SeminarList' }">
          <a @click.prevent="changeTab('SeminarList')">MY 세미나 등록 내역</a>
        </li>
        <li :class="{ 'on': tab === 'DrugAskList' }">
          <a @click.prevent="changeTab('DrugAskList')">샘플/방문/이메일 답변 신청 내역</a>
        </li>
      </ul>
    </div>

    <div class="tab-contents-in">
      <component
        :is="tab"
        ref="tab"
        :calendar-fr="calendarFr"
        :calendar-to="calendarTo"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import dayjs from 'dayjs'
import '@/libs/vue2-datepicker/calendar-vue.css'
import { locale } from '@/libs/vue2-datepicker/lang/ko-kr'

import CommentList from '@/views/apps/mypage/act-history/CommentList.vue'
import SurveyList from '@/views/apps/mypage/act-history/SurveyList.vue'
import SeminarList from '@/views/apps/mypage/act-history/SeminarList.vue'
import DrugAskList from '@/views/apps/mypage/act-history/DrugAskList.vue'

export default {
  components: {
    DatePicker,
    CommentList,
    SurveyList,
    SeminarList,
    DrugAskList,
  },
  data() {
    return {
      tab: 'CommentList',
      monthCheck: 1,
      calendarFr: null,
      calendarTo: null,
      datePicker: {
        lang: locale(),
      },
    }
  },
  watch: {
    monthCheck() {
      this.calendarFr = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
      this.calendarTo = dayjs().format('YYYY-MM-DD')
    },
    calendarFr(now, old) {
      if (now > this.calendarTo) {
        this.calendarFr = old

        this.$parent.showAlertDialog("시작일은 종료일보다 클 수 없습니다.")
      }
    },
    calendarTo(now, old) {
      if (now < this.calendarFr) {
        this.calendarTo = old

        this.$parent.showAlertDialog("종료일은 시작일보다 작을 수 없습니다.")
      }
    },
  },
  created() {
    this.calendarFr = dayjs().add(-this.monthCheck, 'month').format('YYYY-MM-DD')
    this.calendarTo = dayjs().format('YYYY-MM-DD')
  },
  methods: {
    changeTab(tab) {
      this.tab = tab
    },
    searchHistory() {
      if (this.calendarFr == null) this.$parent.showAlertDialog("시작일을 선택해주세요.")
      else if (this.calendarTo == null) this.$parent.showAlertDialog("종료일을 선택해주세요.")
      else {
        this.$refs.tab.searchDateFr = this.calendarFr
        this.$refs.tab.searchDateTo = this.calendarTo

        this.$refs.tab.getHistory(1)
      }
    },
  },
}
</script>
