<template>
  <div>
    <div class="tb-list-type">
      <table>
        <colgroup>
          <col style="width: 140px">
          <col style="width: 140px">
          <col style="width: auto">
          <col style="width: 600px">
        </colgroup>

        <thead>
          <tr>
            <th>작성일</th>
            <th>구분</th>
            <th>게시글 제목</th>
            <th>작성 내용</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in commentList"
            :key="index"
          >
            <td>{{ item.createDate | $dateFormatter('YYYY. MM. DD') }}</td>
            <td>{{ item.contentType }}</td>
            <td><p class="ellipsis-txt">
              {{ item.title }}
            </p></td>
            <td v-if="item.deleteType == 'admin'">
              <p class="delete-guide">
                <i />관리자에 의해 삭제된 댓글입니다.
              </p>
            </td>
            <td v-else-if="item.deleteType == 'user'">
              <p class="delete-guide">
                <i />작성자에 의해 삭제된 댓글입니다.
              </p>
            </td>
            <td v-else>
              <div class="more-text-wrap">
                <p class="ellipsis-txt">
                  {{ item.comment }}
                </p>
                <div
                  class="more-text-balloon"
                  style="display: none"
                >
                  {{ item.comment }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      :page="currentPage"
      :records="totalRecord"
      :per="pageCount"
      @paging="pageUpdate"
    />
  </div>
</template>

<script>
import axios from '@axios'
import Pagination from '@/components/controls/Pagination.vue'

export default {
  components: {
    Pagination,
  },
  props: {
    calendarFr: {
      type: String,
      required: false,
      default: null,
    },
    calendarTo: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      commentList: [],
      searchDateFr: this.calendarFr,
      searchDateTo: this.calendarTo,
      currentPage: 1,
      pageCount: 12,
      totalRecord: 0,
    }
  },
  watch: {
    currentPage() {
      this.getHistory()
    },
  },
  created() {
    if (this.searchDateFr !== null && this.searchDateTo !== null) this.getHistory()
  },
  methods: {
    getHistory(page = this.currentPage) {
      if (page !== this.currentPage) this.currentPage = page

      axios.post("/fu/user/act/comment/list", {
        search: {
          searchDateFr: this.searchDateFr,
          searchDateTo: this.searchDateTo,
        },
        pagination: {
          number: page,
          count: this.pageCount,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          this.commentList = items
          this.totalRecord = totalRecord
        })
        .catch(error => {
          this.$parent.$parent.showAlertDialog(error.response.data)
        })
    },
    pageUpdate(page) {
      this.currentPage = page
    },
  },
}
</script>
