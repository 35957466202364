var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pg-wrap" },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list,
              expression: "list",
            },
          ],
          staticClass: "btn-prev",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.dispatch(_vm.first)
            },
          },
        },
        [_vm._v(" 이전 ")]
      ),
      _vm._l(_vm.list, function (p) {
        return _c(
          "a",
          {
            key: p,
            class: { "current-location": p === _vm.currentPage },
            on: {
              click: function ($event) {
                return _vm.dispatch(p)
              },
            },
          },
          [_vm._v(" " + _vm._s(p) + " ")]
        )
      }),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list,
              expression: "list",
            },
          ],
          staticClass: "btn-next",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.dispatch(_vm.end)
            },
          },
        },
        [_vm._v(" 다음 ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }