var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tb-list-type" }, [
        _c("table", [
          _vm._m(0),
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.commentList, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("$dateFormatter")(item.createDate, "YYYY. MM. DD")
                    )
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(item.contentType))]),
                _c("td", [
                  _c("p", { staticClass: "ellipsis-txt" }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                ]),
                item.deleteType == "admin"
                  ? _c("td", [_vm._m(2, true)])
                  : item.deleteType == "user"
                  ? _c("td", [_vm._m(3, true)])
                  : _c("td", [
                      _c("div", { staticClass: "more-text-wrap" }, [
                        _c("p", { staticClass: "ellipsis-txt" }, [
                          _vm._v(" " + _vm._s(item.comment) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "more-text-balloon",
                            staticStyle: { display: "none" },
                          },
                          [_vm._v(" " + _vm._s(item.comment) + " ")]
                        ),
                      ]),
                    ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("pagination", {
        attrs: {
          page: _vm.currentPage,
          records: _vm.totalRecord,
          per: _vm.pageCount,
        },
        on: { paging: _vm.pageUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "140px" } }),
      _c("col", { staticStyle: { width: "140px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "600px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("작성일")]),
        _c("th", [_vm._v("구분")]),
        _c("th", [_vm._v("게시글 제목")]),
        _c("th", [_vm._v("작성 내용")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "delete-guide" }, [
      _c("i"),
      _vm._v("관리자에 의해 삭제된 댓글입니다. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "delete-guide" }, [
      _c("i"),
      _vm._v("작성자에 의해 삭제된 댓글입니다. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }