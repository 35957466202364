<template>
  <div
    class="pg-wrap"
  >
    <button
      v-show="list"
      class="btn-prev"
      @click.prevent="dispatch(first)"
    >
      이전
    </button>
    <a
      v-for="p in list"
      :key="p"
      :class="{ 'current-location': p === currentPage }"
      @click="dispatch(p)"
    >
      {{ p }}
    </a>
    <button
      v-show="list"
      class="btn-next"
      @click.prevent="dispatch(end)"
    >
      다음
    </button>
  </div>
</template>

<script>
export default {
  props: {
    // 현재 페이지 번호
    page: {
      type: Number,
      required: true,
    },

    // 총 레코드 수
    records: {
      type: Number,
      default: 0,
    },

    // 한 페이지 내 표시 레코드 개수
    per: {
      type: Number,
      default: 10,
    },

    // 페이지 목록 표시 단위
    chunk: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      first: null,
      end: null,
      list: null,
      currentPage: null,
    }
  },

  watch: {
    page() {
      this.render()
    },

    records() {
      this.render()
    },
  },

  methods: {
    dispatch(page) {
      if (page > 0) {
        this.$emit("paging", page)
      }
    },

    render() {
      const totalPage = Math.ceil(this.records / this.per)
      const currentPage = this.page
      const first = currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null
      const end = totalPage !== currentPage
        ? parseInt(currentPage, 10) + parseInt(1, 10)
        : null

      const startIndex = (Math.ceil(currentPage / this.chunk) - 1) * this.chunk + 1
      const endIndex = startIndex + this.chunk > totalPage ? totalPage : startIndex + this.chunk - 1
      const list = []
      for (let index = startIndex; index <= endIndex; index += 1) {
        list.push(index)
      }

      // console.log(first, end, list, currentPage)
      this.first = first
      this.end = end
      this.list = list
      this.currentPage = currentPage
    },
  },
}
</script>
