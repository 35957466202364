var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tb-list-type" }, [
        _c("table", [
          _vm._m(0),
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.surveyList, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("$dateFormatter")(item.createDate, "YYYY. MM. DD")
                    )
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(item.surveyType))]),
                _c("td", [
                  _c("p", { staticClass: "ellipsis-txt" }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                ]),
                _c("td", [
                  _c("p", { staticClass: "ellipsis-txt" }, [
                    _vm._v(" " + _vm._s(item.reward) + " "),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("pagination", {
        attrs: {
          page: _vm.currentPage,
          records: _vm.totalRecord,
          per: _vm.pageCount,
        },
        on: { paging: _vm.pageUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "140px" } }),
      _c("col", { staticStyle: { width: "140px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "320px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("참여일")]),
        _c("th", [_vm._v("구분")]),
        _c("th", [_vm._v("설문 제목")]),
        _c("th", [_vm._v("리워드")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }